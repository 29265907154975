
import React from "react";

export default class MyFormUnsuscribe extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (

            <form
            onSubmit={this.submitForm}
            action="https://formspree.io/mnbblykm"
            method="POST"
            className="row topBefore padding5_top"
            >
                <div className="small-11 columns">
                    <input type="email" className="login-box-input" name="Unsubscribe" id="userEmail" required data-error="NEW ERROR MESSAGE" placeholder="name@email.com"/>
                </div>
                <div className="small-11 columns padding5_top">
                {status === "SUCCESS" ? <input className="button" type="submit" id="send_message" name="submit" value="Your email has been removed." /> : <input className="button" type="submit" id="send_message" name="submit" value="Unsubscribe" />}
                    {status === "ERROR" && <input className="button" type="submit" id="send_message" name="submit" value="Ooops! There was an error." />}
                </div>


            </form>



    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}