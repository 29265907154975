import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from "gatsby"
import PageTransition from 'gatsby-plugin-page-transitions'
import MyFormUnsuscribe from '../components/myformunsuscribe'
import Animated_Topo from '../images/animated_topography.svg'
let pathPrefix = `/`
export default () => (

<div>
    <PageTransition>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Biscaypla.in - Monthly picks for people interested in creativity</title>
        <meta name="description" content="Biscay Plain provides 5 free articles, book recommendations and guides about creativity in your inbox every last Sunday morning of the month"/>
        <link rel="canonical" href="https://biscaypla.in/unsubscribe" />
        </Helmet>

    <div className="body_full">
    <h1 className="hidden-h1">Biscay Plain - Unsubscribe</h1>
    <img src={Animated_Topo} className="topo_img" alt="topo"/>



    <div className="row expanded body_full">


    <div className="small-12 medium-8 columns">
            <div className="row full-left-pannel">
            <div className="small-12"><Link to="/"><img className="logo_bp" src={require('../images/biscay_plain_logo.svg')} alt="biscay plain logo"/></Link></div>
                <div className="small-12 columns">
                    <h2>You will be missed...</h2>
                    <h5>Unsuscribe from our email list</h5>
                    <p>If you'd no longer like to receive email from convy please enter your email address below. Your request will be effective within the next 24 hours.</p>
                    <MyFormUnsuscribe/>
                </div>
            </div>
        </div>



        <div className="footer-medium columns small-12 align-self-bottom">
                    <div className="row align-center">
                        <div className="column small-3">
                            <small><Link className="menu__item" to="#"><span className="menu__item-name">Get in touch</span></Link></small>
                        </div>
                        <div className="column small-3">
                            <small><Link className="menu__item" to="#"><span className="menu__item-name">Suggest your article</span></Link></small>
                        </div>
                        <div className="column small-3">
                            <small><Link className="menu__item" to="/legal/"><span className="menu__item-name">Privacy policy</span></Link></small>
                        </div>
                        <div className="column small-3">
                            <small>&copy; BiscayPlain 2011-2021</small>
                        </div>
                    </div>
                </div>
    </div>


    </div>


    </PageTransition>

</div>
)

